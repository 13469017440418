import { createApp } from 'vue'
import App from './App.vue'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App)

const options = {
    confirmButtonColor: '#000000',
    cancelButtonColor: '#000000',
  };

app.use(VueSweetalert2, options);

app.mount('#app')

<template>
  <div class="launch" v-if="state == 'failed'">
    <h1>Something's up.</h1>
    <h3>Something funky is going on with our servers. Please try again later.</h3>
  </div>

  <div class="launch" v-if="state == 'early'">
    <h1>You're early.</h1>
    <h3>The login screen will unlock 15 minutes before the livestream begins.<br>This page will automatically refresh
      when you can login.<br>Be sure to have your KAMAR, SchoolPoint or SchoolBridge login details handy.</h3>
  </div>

  <div class="launch" v-if="state == 'welcome'">
    <h1>Hi there!</h1>
    <h3>We'll need to verify your identity before you can access the livestream.<br>This shouldn't take long.</h3>
    <button v-on:click="state = 'login'" type="submit" class="outline purple-white">Continue</button>
  </div>

  <div class="launch" v-if="state == 'login'">
    <h1>Login</h1>
    <h3>Please login using your KAMAR, SchoolBridge or SchoolPoint login details.</h3>
    <label class="label" for="username">Username</label><br>
    <input type="text" id="username" name="username"><br>
    <label class="label" for="password">Password</label><br>
    <input type="password" id="password" name="password"><br>
    <button v-on:click="logMeIn()" id="contact-submit" type="submit" class="outline purple-white">Log me in!</button>
  </div>

  <div class="stream" v-if="state == 'stream'">
    <h3>Logged in as {{ greeting }}<span class="right">{{ title }}</span></h3>
    <div v-html="holyGrail" class="stream-player">

    </div>
  </div>

  <div class="launch" v-if="state == 'finished'">
    <h1>{{ bye }}</h1>
    <h3>The livestream has now finished.<br>Replays will be available later tonight.</h3>
  </div>
</template>

<script>
  export default {
    name: 'Landing',
    props: {

    },
    data() {
      return {
        state: 'loading',
      };
    },
    async created() {
      window.toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "preventDuplicates": false,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "15000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
      }
      window.loading_screen = window.pleaseWait({
        logo: "static/img/aquinas-loader.png",
        backgroundColor: '#000000',
        loadingHtml: "<center><p style=\"font-family: proxima-nova, sans-serif;\">Connecting...</p><div class=\"sk-wave\"> <div class=\"sk-wave-rect\"></div><div class=\"sk-wave-rect\"></div> <div class=\"sk-wave-rect\"></div><div class=\"sk-wave-rect\"></div><div class=\"sk-wave-rect\"></div></div><center>"
      });

      var self = this;

      const stateRes = await fetch('https://gl.noahball.com/api/v1/status', {
        method: 'get',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }).catch(err => {
        console.log(err);
        window.loading_screen.finish();
        this.$swal.fire(
          'Something went wrong',
          'Failed to connect to the server.<br>Error: ' + err,
          'error'
        )
        self.stateUpdate('failed');
      })

      const status = await stateRes.status;
      if (status !== 200) {
        window.loading_screen.finish();
        this.$swal.fire(
          'Something went wrong',
          'Failed to connect to the server.<br>Error: Server returned status code other than 200',
          'error'
        )
        self.stateUpdate('failed');
      }

      const stateResults = await stateRes.text()
      self.stateUpdate(stateResults);
      window.loading_screen.finish();
      console.log('sent')

      window.socket.on('streamStateUpdate', function (state, load, loadMessage) {
        if (load == 'finish') {
          window.loading_screen.finish();
        } else if (load == 'start') {
          window.loading_screen = window.pleaseWait({
            logo: "static/img/aquinas-loader.png",
            backgroundColor: '#000000',
            loadingHtml: `<center><p style="font-family: proxima-nova, sans-serif;">${loadMessage}</p><div class="sk-wave"> <div class="sk-wave-rect"></div><div class="sk-wave-rect"></div> <div class="sk-wave-rect"></div><div class="sk-wave-rect"></div><div class="sk-wave-rect"></div></div><center>`
          });
        }
        self.stateUpdate(state);
      });
      window.socket.on('prizegivingTitle', function (text) {
        self.titleUpdate(text);
      });
      window.socket.on('toast', function (text) {
        window.toastr["info"](text, "Announcement");
      });
    },
    methods: {
      async stateUpdate(state) {
        var _this = this;
        _this.state = state;
        console.log('state to ' + state);
      },
      async titleUpdate(text) {
        var _this = this;
        _this.title = text;
      },
      async startStream(results, detailsResults, photoResults) {
        var _this = this;
        _this.state = 'stream';
        _this.fullName = detailsResults.fullName;
        _this.firstName = detailsResults.firstName;
        _this.accountType = results.accountType;
        if (results.accountType == 'Student') {
          _this.greeting = _this.fullName;
        } else if (results.accountType == 'Primary Caregiver') {
          _this.greeting = `a Primary Caregiver for ${_this.fullName}`;
        } else if (results.accountType == 'Caregiver') {
          _this.greeting = `a Caregiver for ${_this.fullName}`;
        } else if (results.accountType == 'Teacher') {
          _this.greeting = `Teacher - ${_this.fullName}`;
        }

        if (results.accountType == 'Student') {
          _this.bye = `Thanks for watching, ${_this.firstName}!`;
        } else if (results.accountType == 'Primary Caregiver') {
          _this.bye = `Thanks for watching!`;
        } else if (results.accountType == 'Caregiver') {
          _this.bye = `Thanks for watching!`;
        } else if (results.accountType == 'Teacher') {
          _this.bye = `Thanks for watching, ${_this.fullName}`;
        }

        _this.holyGrail =
          `<iframe width="560" height="315" src="${results.holyGrail}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        console.log(results + detailsResults + photoResults)
      },
      async logMeIn() {
        var _this = this;
        window.loading_screen = window.pleaseWait({
          logo: "static/img/aquinas-loader.png",
          backgroundColor: '#000000',
          loadingHtml: "<center><p style=\"font-family: proxima-nova, sans-serif;\">Logging you in...</p><div class=\"sk-wave\"> <div class=\"sk-wave-rect\"></div><div class=\"sk-wave-rect\"></div> <div class=\"sk-wave-rect\"></div><div class=\"sk-wave-rect\"></div><div class=\"sk-wave-rect\"></div></div><center>"
        });

        const res = await fetch('https://gl.noahball.com/api/v1/login', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            username: document.getElementById('username').value,
            password: document.getElementById('password').value,
          })
        }).catch(err => {
          console.log(err);
          window.loading_screen.finish();
          this.$swal.fire(
            'Something went wrong',
            'Failed to connect to the server.<br>Error: ' + err,
            'error'
          )
        })

        const results = await res.json()

        if (results.state == 'error') {
          this.$swal.fire(
            'Something went wrong',
            results.error,
            'error'
          )
        } else if (results.state == 'success') {
          document.cookie = `magicalKey=${results.magicalKey}`;
          document.cookie = `studentID=${results.studentID}`;

          const detailsRes = await fetch('https://gl.noahball.com/api/v1/details', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              magicalKey: results.magicalKey,
              studentID: results.studentID,
            })
          }).catch(err => {
            console.log(err);
            window.loading_screen.finish();
            this.$swal.fire(
              'Something went wrong',
              'Failed to connect to the server.<br>Error: ' + err,
              'error'
            )
          })

          const detailsResults = await detailsRes.json()

          if (detailsResults.state == 'error') {
            this.$swal.fire(
              'Something went wrong',
              detailsResults.error,
              'error'
            )
          } else if (detailsResults.state == 'success') {
            const photoRes = await fetch('https://gl.noahball.com/api/v1/photo', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                magicalKey: results.magicalKey,
                studentID: results.studentID,
              })
            }).catch(err => {
              console.log(err);
              window.loading_screen.finish();
              this.$swal.fire(
                'Something went wrong',
                'Failed to connect to the server.<br>Error: ' + err,
                'error'
              )
            })

            const photoResults = await photoRes.json()

            if (photoResults.state == 'error') {
              this.$swal.fire(
                'Something went wrong',
                detailsResults.error,
                'error'
              )
            } else if (photoResults.state == 'success') {
              if (results.accountType == 'Student') {
                this.$swal.fire(
                  'Woop woop!',
                  `You are now logged in as ${detailsResults.fullName} with ${results.accountType} access.<br>Student ID: ${results.studentID}<br><br><img src="${photoResults.url}" height="200">`,
                  'success'
                )
              } else {
                this.$swal.fire(
                  'Woop woop!',
                  `You are now logged in as a ${results.accountType} for ${detailsResults.fullName}.<br>Student ID: ${results.studentID}<br><br><img src="${photoResults.url}" height="200">`,
                  'success'
                )
              }
              _this.state = 'limbo';
              this.startStream(results, detailsResults, photoResults);
            }
          }
        }
        window.loading_screen.finish();
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  button {
    cursor: pointer;
    outline: none;
    position: relative;
    z-index: 3;
    background: transparent;
    color: #FFFFFF;
    font-size: 14px;
    border-color: #FFFFFF;
    border-style: solid;
    border-width: 2px;
    border-radius: 22px;
    font-family: proxima-nova, sans-serif;
    padding: 10px 40px;
    transition: all 0.2s linear;
    font-size: 16px;
    margin-top: 1rem;
    background: transparent;
  }

  button:hover {
    color: #051622;
    background: #FFFFFF;
    border-color: #FFFFFF;
  }

  button a {
    text-decoration: none;
  }

  input,
  textarea {
    margin-bottom: 1vh;
    margin-top: 1vh;
    background-color: #0c1114;
    border: none;
    border-radius: 10px;
    color: #FFFFFF;
    height: 2rem;
    width: 90%;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    font-family: proxima-nova, sans-serif;
  }

  input:focus,
  textarea:focus {
    outline-width: 0;
  }

  label {
    font-family: proxima-nova, sans-serif;
  }

  .right {
    float: right;
  }
</style>
<template>
  <Landing />
</template>

<script>
  import Landing from './components/Landing.vue'

  export default {
    name: 'App',
    components: {
      Landing
    }
  }
</script>

<style>
  @import url("https://use.typekit.net/uyn6qjc.css");

  body {
    background-color: #000000;
    color: #FFFFFF;
  }

  #app {
    font-family: proxima-nova, sans-serif;
    -moz-osx-font-smoothing: grayscale;
  }

  .launch {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .stream-player {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 90vh;
  }

  .stream-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .swal2-container, .toast-title, .toast-message {
    font-family: proxima-nova, sans-serif;
  }
</style>